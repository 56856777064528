import Tag from '@atoms/new/tag';
import Button from '@atoms/new/button';
import { numberToAlphabet } from '@components/Presentation/ai/utils';
import { CheckRounded, RefreshRounded } from '@mui/icons-material';
interface BlockProps {
  feature: string;
  title: string;
  icon: React.ReactNode;
  options?: { title: string; checked: boolean }[];
  isSelected?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  onGenerateNew?: () => void;
  isGenerated?: boolean;
}

export const Block = ({
  feature,
  title,
  options,
  icon,
  isSelected,
  isLoading,
  onGenerateNew = () => {},
  onClick = () => {},
  isGenerated = true,
}: BlockProps) => {
  const showOptions = feature !== 'Rating Polls' && options?.length;

  return (
    <div
      onClick={onClick}
      className={`bg-white dark:bg-grey-800/60 relative select-none cursor-pointer flex flex-col py-4 border border-grey-200 dark:border-grey-800 rounded-md hover:border-primary-500 dark:hover:border-primary-500 ${
        isSelected ? 'border-primary-500 dark:border-primary-500' : ''
      }`}
    >
      {isSelected && (
        <div className="absolute top-0 right-0 left-0 bottom-0 bg-primary-600/40 z-10 rounded-md" />
      )}
      <div className="flex items-center justify-between mb-3 px-4">
        <Tag className="bg-transparent border dark:border-grey-800" startContent={icon}>
          {feature}
        </Tag>

        {isGenerated && (
          <Button
            tooltip={{
              content: 'Generate new',
            }}
            isIconOnly
            onClick={onGenerateNew}
            size="sm"
            kind="secondary"
            startContent={
              isLoading ? null : <RefreshRounded fontSize="small" />
            }
          />
        )}
      </div>

      <div className={` px-4 ${showOptions ? 'border-b pb-4 dark:border-grey-800' : ''}`}>
        <p className="text-grey-900 dark:text-grey-200 font-semibold">{title}</p>
      </div>

      {showOptions && options && (
        <div className="px-4 mt-4">
          {options.map((option, index) => (
            <div
              key={index}
              className={`flex items-center gap-2 pb-3 ${
                index === 0 ? '' : 'mt-3'
              } ${options.length === index + 1 ? '' : 'border-b dark:border-grey-800'}`}
            >
              <p className="text-grey-900 dark:text-grey-200 font-semibold text-sm">{`${numberToAlphabet(
                index + 1
              )}.`}</p>
              <p className="text-grey-900 dark:text-grey-200 font-normal text-sm">{option.title}</p>

              {option.checked && <CheckRounded fontSize="small" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
