import Button from '@atoms/new/button';
import { isMicrosoftTeamsApp } from '@teams-app/helpers';
import { Tooltip } from 'react-tooltip';

interface GenAiResultFooterProps {
  selectedBlocks: number;
  onAddToSlide: () => void;
}

const getButtonText = (count: number) => {
  if (count === 0) return 'Add slides';
  if (count === 1) return 'Add slide';
  return `Add ${count} slides`;
};

export const GenAiResultFooter = ({
  selectedBlocks,
  onAddToSlide,
}: GenAiResultFooterProps) => {
  return (
    <div className={`select-none flex items-center w-full ${isMicrosoftTeamsApp() ? 'justify-between' : 'justify-end'}`}>
      {isMicrosoftTeamsApp() && <>
        <div
          data-tooltip-id='ai-warning-message'
          data-tooltip-content='For any issues contact support@streamalive.com'>
          AI generated content may be incorrect.
        </div>
        <Tooltip id="ai-warning-message"
          place="top-start"
          style={{
            padding: '8px',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            borderRadius: '8px',
            fontSize: '14px',
          }}
        />
      </>}
      <Button isDisabled={selectedBlocks === 0} onClick={onAddToSlide}>
        {getButtonText(selectedBlocks)}
      </Button>
    </div>
  );
};
