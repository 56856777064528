import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@atoms/new/modal';
import Button from '@atoms/new/button';
import { useContext, useEffect, useState } from 'react';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {
  PinDropRounded,
  HelpRounded,
  CloudRounded,
  EmojiEmotionsRounded,
  ChatRounded,
  StarHalfRounded,
  LinkRounded,
  Groups2Rounded,
} from '@mui/icons-material';

import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deleteAutoComplete,
  generateSlides,
  getAutoComplete,
} from '@services/generative-ai';
import { useRoSContext } from '../context/RoSContext';
import {
  type InteractionType,
  type InteractionOptions,
} from '../types';
import { v4 as uuidv4 } from 'uuid';
import { useDebounce, usePrevious } from '@uidotdev/usehooks';

import { GenAiResultFooter } from './gen-ai-result-footer';
import { GenAiMainContent } from './gen-ai-main-content';
import { panelBtnEnum } from '../constants';
import {
  getInteractionSettings,
} from '@services/utils.service';
import useFetchThemes from '@components/themes/hooks/useFetchThemes';
import { getUserDetails } from '@services/youtube-platform.service';
import { TeamsDataContext } from '@components/context/TeamsContext';

export const interactionsMap = {
  pollWithOptions: {
    title: 'Power Polls',
    icon: (
      <img className="w-5 h-5" src="/images/ros/interactions/bar_chart.svg" />
    ),
    type: 'poll',
  },
  freeForm: {
    title: 'Open-Ended Polls',
    icon: (
      <img
        className="w-5 h-5"
        src="/images/ros/interactions/open_ended_polls.svg"
      />
    ),
    type: 'poll',
  },
  map: {
    title: 'Magic Maps',
    icon: <PinDropRounded fontSize="small" className="text-grey-700" />,
    type: 'question',
  },
  wordCloud: {
    title: 'Wonder Words',
    icon: <CloudRounded fontSize="small" className="text-grey-700" />,
    type: 'question',
  },
  wheel: {
    title: 'Choice Circle',
    icon: <img className="w-5 h-5" src="/images/ros/interactions/wheel.svg" />,
    type: 'question',
  },
  emojisEverywhere: {
    title: 'Emojis Everywhere',
    icon: <EmojiEmotionsRounded fontSize="small" className="text-grey-700" />,
    type: 'question',
  },
  talkingTiles: {
    title: 'Talking Tiles',
    icon: (
      <img
        className="w-5 h-5"
        src="/images/ros/interactions/talking_tiles.svg"
      />
    ),
    type: 'question',
  },
  transientThought: {
    title: 'Transient Thoughts',
    icon: <ChatRounded fontSize="small" className="text-grey-700" />,
    type: 'question',
  },
  newWheel: {
    title: 'Winning Wheel',
    icon: <img className="w-5 h-5" src="/images/ros/interactions/wheel.svg" />,
    type: 'question',
  },
  ratingPolls: {
    title: 'Rating Polls',
    icon: <StarHalfRounded fontSize="small" className="text-grey-700" />,
    type: 'poll',
  },
  multipleChoice: {
    title: 'Multiple Choice',
    icon: (
      <img
        className="w-5 h-5"
        src="/images/ros/interactions/multiple-choice.svg"
      />
    ),
    type: 'question',
  },
  'Quick Questions': {
    title: 'Quick Questions',
    icon: (
      <HelpRounded style={{ fontSize: '20px' }} className="text-grey-700" />
    ),
    type: 'question',
  },
  linkLibrary: {
    title: 'Link Library',
    icon: (
      <LinkRounded style={{ fontSize: '20px' }} className="text-grey-700" />
    ),
    type: 'question',
  },
  textTrack: {
    title: 'Text Track',
    icon: (
      <img className="w-5 h-5" src="/images/ros/interactions/track_text.svg" />
    ),
    type: 'question',
  },
  fantasticFans: {
    title: 'Fantastic Fans',
    icon: (
      <Groups2Rounded style={{ fontSize: '20px' }} className="text-grey-700" />
    ),
    type: 'question',
  },
};

interface CreateWithAiModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onOpenChange?: () => void;
  onRosPreviewModalOpen: () => void;
  presets?: { topic: string; audience: string };
}

export interface BlockParams {
  feature: InteractionType;
  title: string;
  options?: { title: string; checked: boolean }[];
}

export interface AutocompleteItem {
  id: string;
  topic: string;
  audience: string;
  isDefault?: boolean;
  tag?: string;
  isSelected?: boolean;
}

export const CreateWithAiModal = ({
  isOpen,
  presets,
  onOpenChange,
  onClose = () => {},
}: CreateWithAiModalProps) => {
  const [topic, setTopic] = useState('');
  const [audience, setAudience] = useState('');
  const [result, setResult] = useState<BlockParams[]>([]);
  const [selectedBlocks, setSelectedBlocks] = useState<string[]>([]);
  const [page, setPage] = useState(1);

  const { mutate, isPending, isError, reset } = useMutation({
    mutationFn: () => generateSlides({ topic: query, audience, page }),
    onSuccess: (data) => {
      if (data) {
        if (data?.entity?.length === 0) return;

        if (!Array.isArray(data?.entity)) return;

        setResult(data?.entity);
        setSelectedBlocks([]);
      }
    },
  });

  const { teamContext } = useContext(TeamsDataContext);
  const {
    addNewSlide,
    updateRoSTitle,
    updateSelectedSlideIndex,
    toggleSelectedPanelBtn,
  } = useRoSContext();
  const fetchSlides = () => {
    setAutoComplete([]);
    setResult([]);
    setShowResult(true);
    mutate();
  };

  const [generateNewIsLoading, setGenerateNewIsLoading] = useState<any>({});
  const [showResult, setShowResult] = useState(false);

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const previousQuery = usePrevious(debouncedQuery);

  const [autocomplete, setAutoComplete] = useState<AutocompleteItem[]>([]);
  const [showAutoComplete, setShowAutoComplete] = useState(false);

  useEffect(() => {
    if (previousQuery?.includes(debouncedQuery)) return;
    if (!debouncedQuery) {
      setAutoComplete([]);
      return;
    }

    if (!showAutoComplete) return;

    getAutoComplete(debouncedQuery)
      .then((res: any) => {
        if (res.status) {
          setAutoComplete(res.entity);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [debouncedQuery, showAutoComplete, previousQuery]);

  const handleClose = () => {
    setPage(1);
    setResult([]);
    setAutoComplete([]);
    setShowResult(false);
    setAudience('');
    setSelectedBlocks([]);
    setQuery('');
    reset();
  };

  const { getThemeSettings } = useFetchThemes();

  const { data: userSettings } = useQuery({
    queryKey: ['getUserDetails', teamContext?.oid],
    queryFn: () => getUserDetails(teamContext?.oid),
    enabled: !!teamContext?.oid,
  });

  const handleAddSlides = (slides: BlockParams[]) => {
    updateRoSTitle(topic);
    const { colors = {}, settings = {} } = getThemeSettings();

    slides
      .reverse()
      .map((selectedBlock) => {
        const options: InteractionOptions[] = [];
        const featureSetting = getInteractionSettings(
          selectedBlock.feature,
          userSettings?.entity?.settings
        );

        if (selectedBlock.options) {
          selectedBlock.options.forEach((option) => {
            options.push({
              checked: option.checked,
              body: option.title,
              id: uuidv4(),
            });
          });
        }

        addNewSlide(selectedBlock.feature, {
          ...selectedBlock,
          options,
          setting: {},
          color: {} as any,
          type: selectedBlock.feature,
          title: selectedBlock.title,
        });

        return {
          ...selectedBlock,
          options,
          setting: { ...featureSetting, ...settings },
          color: colors,
          type: selectedBlock.feature,
          title: selectedBlock.title,
        };
      });

    updateSelectedSlideIndex(0);
    toggleSelectedPanelBtn(panelBtnEnum.content)();
  };

  const { mutateAsync: handleDeleteAutoComplete } = useMutation({
    mutationFn: deleteAutoComplete,
  });

  const onDeleteAutoComplete = async (topic: string) => {
    try {
      setAutoComplete((prev) => prev.filter((i) => i.topic !== topic));
      await handleDeleteAutoComplete(topic);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (presets && presets.topic !== '') {
      setTopic(presets.topic);
      setQuery(presets.topic);
      setAudience(presets.audience);
      fetchSlides();
    }
  }, [presets]);

  return (
    <Modal
      isKeyboardDismissDisabled
      scrollBehavior="inside"
      disableOuterScroll={true}
      size="lg"
      isOpen={isOpen}
      onClose={() => {
        handleClose();
        onClose();
        sessionStorage.removeItem('topic');
        sessionStorage.removeItem('audience');
      }}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        <ModalHeader className={`${showResult ? 'pb-6' : 'mb-0 mt-8'}`}>
          <div className="flex items-center gap-3 justify-between w-full">
            {showResult ? (
              <div className="flex-1">
                <Button
                  size="sm"
                  kind="secondary"
                  isIconOnly
                  onPress={handleClose}
                  startContent={<ArrowBackRoundedIcon fontSize="small" />}
                />
              </div>
            ) : (
              <div className="flex-1" />
            )}
            <div className="flex items-center gap-3">
              <p className="text-xl text-grey-900 dark:text-grey-200">
                Create interactive slides with AI
              </p>
            </div>
            <div className="flex-1" />
          </div>
        </ModalHeader>
        <ModalBody
          className={`${showResult ? 'pt-0' : 'px-16 py-8'} scrollbar-hide`}
        >
          <GenAiMainContent
            inputPlaceholder='What is the topic of your live session?'
            onDeleteAutoComplete={onDeleteAutoComplete}
            isError={isError}
            setAudience={(value: string) =>
              setAudience((prev) => (prev === value ? '' : value))
            }
            showResult={showResult}
            topic={topic}
            setTopic={setTopic}
            selectedBlocks={selectedBlocks}
            setSelectedBlocks={setSelectedBlocks}
            audience={audience}
            autocomplete={autocomplete}
            fetchSlides={fetchSlides}
            setPage={setPage}
            result={result}
            setGenerateNewIsLoading={setGenerateNewIsLoading}
            generateNewIsLoading={generateNewIsLoading}
            setResult={setResult}
            isPending={isPending}
            query={query}
            onQueryChange={(value) => {
              setQuery(value);
              setShowAutoComplete(true);
            }}
            showAutoComplete={showAutoComplete}
            setShowAutoComplete={setShowAutoComplete}
            onAutocompleteClick={(value) => {
              setTopic(value.topic);
              setQuery(value.topic);
              setAudience(value.audience);
              setShowAutoComplete(false);
              fetchSlides();
            }}
            onClear={() => setSelectedBlocks([])}
            onSelectAll={() => {
              if (selectedBlocks.length === result.length) {
                setSelectedBlocks([]);
              } else {
                setSelectedBlocks(result.map((i) => i.title));
              }
            }}
          />
        </ModalBody>
        {showResult && (
          <ModalFooter>
            <GenAiResultFooter
              selectedBlocks={selectedBlocks.length}
              onAddToSlide={() => {
                handleAddSlides(
                  result.filter((i) => selectedBlocks.includes(i.title))
                );
                handleClose();
                onClose();
              }}
            />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
